body {
  font-family: "Poppins", sans-serif;
}
.checkout-page{
  background-image: url("assets/4.png");
          background-size: cover;
          background-position: center;
          height: 100%;
          margin-top: -10px;
}
.transaction-modal {
  width: 50vw;
  max-width: 50vw;
} 
.checkout-heading{
  color: #68755c;
            font-weight: bold;
}
.otp-input-container {
  display: flex;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.striped-background {
  background-image: url("assets/4.png");
          background-size: cover;
          background-position: center;
          height: 100%;
          margin-top: -10px;
}
.learn-more-btn:hover {
  background: linear-gradient(to top right, #56634c, #6e7b5e);
}
.more-detail-btn:hover {
  background: linear-gradient(to bottom right, #323131, #4a4a4a);
  color: #f0f0f0;
}
.otp-input-container input {
  width: 40px !important;
  height: 40px;
  font-size: 24px;
  text-align: center;
  border: 1px solid #ccc;
  color: #333;
}
.otp-input-container > div:first-child {
  justify-content: space-between;
  width: -webkit-fill-available;
}
.bg-brand-primary {
  background-color: #010101;
}

.bg-brand-darkish {
  background-color: #121212;
}

.bg-brand-secondry {
  background-color: #F3C2AF;
}
.svg-icon svg{
height: 1.75rem;
width: auto;
}
.text-brand-primary {
  color: #68755C;
}

.text-brand-gray {
  color: #454545;
}

.text-brand-secondary {
  color: #F3C2AF !important;
}

.text-brand-greenish {
  color: #68755C
}

.fs-8 {
  font-size: 12px;
}

.fs-7 {
  font-size: 14px;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

/* Navbar Start */
.brand-logo {
  width: 210px;
  margin: 10px;
}
.brand-logo-container{
  position: absolute;
    z-index: 20;
    background: white;
    padding: 26px;
    padding-top: 19px;
    border-radius: 50%;
  }
  .margin-navbar{
    
    margin-right: 90px;
}
.navbar-top{
  margin-top: 100px;
}
.seprater-navbar{
  width: 350px;
}
@media (max-width: 991px) {
  .margin-navbar{
    
    margin-right: 0px;
  }
  .brand-logo {
    width: 125px;
  }
  .brand-logo-container{
    position:relative;
      z-index: 20;
      background: white;
      padding: 0px;
      margin-left: 0px;
  }
  .navbar-top{
  margin-top: 0px;
  }
  .seprater-navbar{
    width: 0px;
  }
}
/* .navbar-nav .nav-link.active::after {
  content: '';
  display: block;
  width: 65px; 
  height: 3px; 
  background-color: #F3C2AF; 
  position: absolute;
  left: -0px;
}
.navbar-nav .nav-link{
  position: relative;
  font-weight: 600;
  font-size: 18px;
  margin-right:20px
}
.nav-item i{
  font-size: 18px;
} */
/* HERO SECTION */
.display-4 {
  color: #121212;
  font-size: 3.4rem;
  font-weight: 700;
}

.hero-image {
  width: 100%;
}

.main-heading-ourexperts span {
  color: rgb(18, 18, 18);
}

/* button */
/* Base styles for gradient button */
.gradient-button {
  font-size: 1vw;
  border: none;
  border-radius: 2px;
  padding: 1% 5%;
  /* width: 100%; */
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.lead {
  font-size: 1.2vw;
  text-align: justify;
}

/* Media queries for responsiveness */
@media screen and (max-width: 989px) {
  .service-details-column {
    text-align: center !important;
  }

  .services-section .accordion-item h6 {
    font-size: 1.7vw !important;
  }
}

@media screen and (max-width: 768px) {
  .hero-image {
    width: 75%;
  }

  .services-description {
    font-size: 2.8vw !important;
  }

  .disclaimer {
    font-size: 2.5vw !important;
  }

  .gradient-button {
    font-size: 2vw !important;
    padding: 1% 5%;
  }

  .display-4 {
    font-size: 5vw;
  }

  .lead {
    font-size: 2.5vw;
  }

  .health-section {
    flex-direction: column;
  }

  .services-section .accordion-item h6 {
    font-size: 2vw !important;
  }
}

@media screen and (max-width: 480px) {
  .hero-image {
    width: 50%;
  }

  .services-description {
    font-size: 2.8vw !important;
  }

  .gradient-button {
    font-size: 2vw !important;
    padding: 1% 5%;
  }

  .display-4 {
    font-size: 5vw;
  }

  .lead {
    font-size: 3vw;
  }

  .services-section .accordion-item h6 {
    font-size: 3vw !important;
  }
}

/* SERVICES */
.accordion {
  width: 100%;
  font-size: 1vw;
  border-radius: 0px;
  border-bottom: 1px solid gray;
}


.services-section .accordion-item {
  font-size: 1vw;
  border: 1px solid gray;
  cursor: pointer;
  border-bottom: none;
  border-radius: 0px;
  background-color: #FAF7F4;
}

.services-section .accordion-item h6 {
  font-size: 1vw;
  cursor: pointer;
  color: #434343;
}

.services-section .active,
.services-section .active h6 {
  background-color: #151515;
  color: white;
}

.service-details-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.disclaimer {
  font-size: 1vw;
}

/* HEALTH */

.health-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.health-circle {
  position: relative;
  width: 300px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.health-item img {
  width: 40px;
  height: auto;
  margin-bottom: 10px;
}