.carousel-slider-class-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5% 10%;
}

.our-experts-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.our-experts-images {
  width: 251px;
  height: 331px;
  margin-bottom: 10px;

  .our-experts-image {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    padding: 10px;
  }
}

.our-experts-name {
  color: #454545;
  font-size: 1.3vw;
}

.our-experts-designation {
  color: #454545;
  font-size: 0.8vw;
}

@media screen and (max-width: 989px) {
  .our-experts-name {
    color: #454545;
    font-size: 2vw;
  }

  .our-experts-designation {
    color: #454545;
    font-size: 1.8vw;
  }
}

@media screen and (max-width: 800px) {
  .our-experts-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .our-experts-name {
    color: #454545;
    font-size: 3.5vw;
  }

  .our-experts-designation {
    color: #454545;
    font-size: 2.8vw;
  }
}

@media screen and (max-width: 480px) {
  .our-experts-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .our-experts-name {
    color: #454545;
    font-size: 3.5vw;
  }

  .our-experts-designation {
    color: #454545;
    font-size: 2.5vw;
  }
}