.learn-more-btn {
    font-size: 1vw;
    font-weight: 500;
    color: #ffffff;
    background: linear-gradient(to top right, #68755c, #869672);
    border: none;
    border-radius: 3px;
    text-decoration: none;
    text-transform: uppercase;
}

.more-detail-btn {
    font-size: 1vw;
    font-weight: 500;
    color: #ffffff;
    background: linear-gradient(to bottom right, #000101, #323131);
    border: none;
    border-radius: 3px;
    text-decoration: none;
    text-transform: uppercase;
}