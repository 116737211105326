.carousel-slider-class {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5% 10%;
}

.content {
  max-width: 800px;
}

.testimonial blockquote {
  margin: 10px 10px 0;
  background: #fdefe8;
  padding: 20px 60px;
  position: relative;
  border: none;
  border-radius: 8px;
  font-style: italic;
  font-size: 1.1vw;
}

.file-show {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;

  .file-icon {
    color: #121212;
    font-size: 30px;

    &:hover {
      color: #efb097;
    }
  }
}

.testimonial blockquote:before,
.testimonial blockquote:after {
  content: "\201C";
  position: absolute;
  font-size: 5vw;
  line-height: 1;
  color: #e1b69e;
  font-style: normal;
}

.testimonial blockquote:before {
  top: 0;
  left: 10px;
}

.testimonial blockquote:after {
  content: "\201D";
  right: 10px;
  bottom: -0.5em;
}

.testimonial .name-mark {
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fdefe8;
  margin: 0 0 0 60px;
}

.testimonial p {
  color: #121212;
  font-size: 1vw;
  text-align: left;
  margin: 8px 0 0 20px;
}

@media screen and (max-width: 989px) {
  .testimonial blockquote {
    margin: 10px 10px 0;
    background: #fdefe8;
    padding: 20px 60px;
    position: relative;
    border: none;
    border-radius: 8px;
    font-style: italic;
    font-size: 1.5vw;
  }

  .testimonial blockquote:before,
  .testimonial blockquote:after {
    content: "\201C";
    position: absolute;
    font-size: 6vw;
    line-height: 1;
    color: #e1b69e;
    font-style: normal;
  }

  .testimonial p {
    color: #121212;
    font-size: 1.5vw;
    text-align: left;
    margin: 8px 0 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .testimonial blockquote {
    margin: 10px 10px 0;
    background: #fdefe8;
    padding: 20px 60px;
    position: relative;
    border: none;
    border-radius: 8px;
    font-style: italic;
    font-size: 2vw;
  }

  .testimonial blockquote:before,
  .testimonial blockquote:after {
    content: "\201C";
    position: absolute;
    font-size: 12vw;
    line-height: 1;
    color: #e1b69e;
    font-style: normal;
  }

  .testimonial p {
    color: #121212;
    font-size: 2vw;
    text-align: left;
    margin: 8px 0 0 20px;
  }
}

@media screen and (max-width: 480px) {
  .content {
    max-width: 800px;
    margin-top: 20px;
  }

  .testimonial blockquote {
    margin: 10px 10px 0;
    background: #fdefe8;
    padding: 20px 60px;
    position: relative;
    border: none;
    border-radius: 8px;
    font-style: italic;
    font-size: 3vw;
  }

  .testimonial blockquote:before,
  .testimonial blockquote:after {
    content: "\201C";
    position: absolute;
    font-size: 18vw;
    line-height: 1;
    color: #e1b69e;
    font-style: normal;
  }

  .testimonial p {
    color: #121212;
    font-size: 2.8vw;
    text-align: left;
    margin: 8px 0 0 20px;
  }
}